

.candidate-data {
    padding: 20px;
    
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .candidate-table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
      }
      
      th {
        background-color: #f2f2f2;
        text-align: left;
      }
      
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
      
      tr:hover {
        background-color: #f1f1f1;
      }
      
      th, td {
        &:nth-of-type(1) {
          width: 5%;
        }
        &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4) {
          width: 15%;
        }
        &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7) {
          width: 10%;
        }
        &:nth-of-type(8) {
          width: 15%;
          text-align: center;
        }
      }
  
      button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        margin: 0 5px;
        
        &:hover {
          color: rgb(148, 78, 28);
        }
      }
    }
  
    .candidate-details, .edit-candidate-form {
      margin-top: 20px;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #f9f9f9;
      
      h3 {
        margin-top: 0;
      }
      
      p {
        margin: 5px 0;
      }
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      div {
        margin-bottom: 15px;
  
        label {
          display: block;
          margin-bottom: 5px;
        }
  
        input, select, textarea {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
  
      button {
        width: 100px;
        padding: 10px;
        margin: 5px 0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
  
        &:first-of-type {
          background-color: #28a745;
          color: white;
          
          &:hover {
            background-color: #218838;
          }
        }
  
        &:last-of-type {
          background-color: #dc3545;
          color: white;
          
          &:hover {
            background-color: #c82333;
          }
        }
      }
    }
  }
  