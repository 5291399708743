@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import "../../styles/variables";
.navbar {
  background-color: $primary-color;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  position: relative;

  a{
    text-decoration: none;
    color: white;
  }

  .navbar-brand {
    font-size: 1.8rem;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .navbar-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    position: relative;
    z-index: 1;

    li {
      position: relative;

      a {
        color: #fff;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 400;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease;

        &:hover {
          color: #ffdd57;
        }

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #ffdd57;
          bottom: -4px;
          left: 0;
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 0.3s ease;
        }

        &:hover::after {
          transform: scaleX(1);
          transform-origin: left;
        }
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, $primary-color,$primary-color);
    clip-path: circle(0% at 100% 0%);
    transition: clip-path 0.5s ease;
    z-index: 0;
  }

  &:hover::before {
    clip-path: circle(150% at 100% 0%);
  }
}
