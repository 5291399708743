@import "../src/styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;

  background-color: $Secondary-color;
}

.footer {
  background-color:$primary-color;
  color: $pure-white;
  text-align: center;
  padding: 1rem;
}
