@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import "../../styles/variables";
.home-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background: url('../../assets/images/2.jpg') no-repeat center center;
  background-size: cover;
  height: 100vh;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;

  .text-section {
    max-width: 50%;
    animation: fadeInLeft 1.5s ease-in-out;

    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
      animation: slideInDown 1.5s ease-in-out;
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      animation: slideInDown 1.5s ease-in-out;
      font-style:normal
    }

    .home-actions {
      display: flex;
      gap: 1rem;

      button {
        background-color:$primary-color;
        border: none;
        padding: 1rem 2rem;
        border-radius: 5px;
        font-size: 1rem;
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease;
        animation: fadeIn 1.5s ease-in-out;

        a {
          color: #fff;
          text-decoration: none;
        }

        &:hover {
          background-color: rgb(197, 157, 129);
        }
      }
    }
  }

  .image-section {
    max-width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    animation: fadeInRight 1.5s ease-in-out;

    img {
      max-width: 100%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.blog-section {
    margin-top: 40px;
  }
  
  .blog-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
  }
  
  .blog-cards::-webkit-scrollbar {
    width: 10px;
  }
  
  .blog-cards::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
  }
  @mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


// Styling
.slider {
	background: white;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 960px;
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
	}
	
	.slide {
		height: 100px;
		width: 250px;
	}
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  form label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  form button {
    padding: 0.5rem 1rem;
  }