$primary-color: #3498db;
$secondary-color: #2ecc71;
$applied-color: #3498db;
$accepted-color: #2ecc71;
$rejected-color: #e74c3c;
$inprogress-color: #f39c12;
$card-hover-color: #f1f1f1;
$background-color: #f9f9f9;
$transition-duration: 0.3s;
$job-detail-bg-color: #ffffff;
$job-detail-border-color: #dee2e6;

.job-post-data {
  background-color: $background-color;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  .job-listing-header {
    text-align: center;
    margin-bottom: 20px;

    .welcome-message {
      color: $primary-color;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .instruction-message {
      color: #555;
      font-size: 16px;
    }
  }

  .job-container {
    display: flex;
    gap: 20px;

    .job-list {
      flex: 1;
      max-width: 400px;

      .job-card {
        background-color: #fff;
        border: 1px solid $job-detail-border-color;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 15px;
        transition: transform $transition-duration ease-in-out, box-shadow $transition-duration ease-in-out;
        cursor: pointer;
        overflow: hidden;
        position: relative;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          background-color: $card-hover-color;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: $primary-color;
          transition: background-color $transition-duration ease-in-out;
        }

        &:hover::before {
          background-color: $secondary-color;
        }

        .job-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            margin: 0;
            color: $primary-color;
            font-size: 20px;
          }

          .job-card-actions {
            display: flex;
            align-items: center;

            button {
              background: none;
              border: none;
              cursor: pointer;
              color: #555;
              font-size: 18px;
              margin-left: 10px;
              transition: color $transition-duration ease-in-out;

              &:hover {
                color: $primary-color;
              }
            }

            .application-count {
              display: flex;
              align-items: center;
              color: $secondary-color;
              font-size: 18px;
              margin-left: 10px;

              svg {
                margin-right: 5px;
              }
            }
          }
        }

        p {
          margin: 5px 0;

          strong {
            color: #333;
          }
        }
      }
    }

    .job-details-container {
      flex: 2;
      background-color: $job-detail-bg-color;
      border: 1px solid $job-detail-border-color;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      animation: fadeIn $transition-duration ease-in;

      h3 {
        color: $primary-color;
        font-size: 22px;
        margin-bottom: 20px;
      }

      p {
        margin: 10px 0;
        font-size: 16px;
        line-height: 1.6;

        strong {
          color: $primary-color;
        }
      }

      .job-detail-section {
        margin-bottom: 20px;
    
        h4 {
          color: $secondary-color;
          font-size: 18px;
          margin-bottom: 10px;
        }
    
        // Improved styling for job details paragraphs
        p {
          background-color: $job-detail-bg-color;
          padding: 15px;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          margin-bottom: 15px;
          transition: background-color $transition-duration ease-in-out;
    
          &:hover {
            background-color: lighten($job-detail-bg-color, 5%);
          }
    
          strong {
            color: $primary-color;
          }
        }
      }
    

      .candidate-list {
        margin-top: 20px;

        h3 {
          color: $primary-color;
          font-size: 20px;
          margin-bottom: 20px;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            border-bottom: 1px solid $job-detail-border-color;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 20px;
            transition: transform $transition-duration ease-in-out, box-shadow $transition-duration ease-in-out;

            &:hover {
              transform: translateY(-5px);
              box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
              background-color: $card-hover-color;
            }

            .candidate-info {
              flex: 1;
              display: flex;
              flex-direction: column;

              div {
                margin: 5px 0;

                strong {
                  color: $secondary-color;
                }
              }
            }

            .candidate-actions {
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .status-button {
                padding: 5px 10px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-weight: bold;
                transition: background-color $transition-duration ease-in-out, opacity $transition-duration ease-in-out;
                color: #fff;
                background-color: #ccc; /* Set background color for unselected buttons */
                opacity: 0.4; /* Set initial opacity for unselected buttons */
              
                &.applied {
                  background-color: $applied-color;
                }
              
                &.accepted {
                  background-color: $accepted-color;
                }
              
                &.rejected {
                  background-color: $rejected-color;
                }
              
                &.inprogress {
                  background-color: $inprogress-color;
                }
              
                &.active {
                  opacity: 1; /* Increase opacity for the selected button */
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                }
              
                &:hover {
                  opacity: 0.8;
                }
              
                &:focus {
                  outline: none;
                }
              }
              
              
              
              
              
              
              
              
              
            }
          }
        }
      }
    }
  }

  .edit-job-form {
    background-color: #fff;
    border: 1px solid $job-detail-border-color;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;

    h3 {
      color: $primary-color;
      margin-bottom: 20px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animations for job cards */
.job-enter {
  opacity: 0;
  transform: scale(0.9);
}
.job-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity $transition-duration, transform $transition-duration;
}
.job-exit {
  opacity: 1;
  transform: scale(1);
}
.job-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity $transition-duration, transform $transition-duration;
}

/* Animations for candidate list items */
.candidate-enter {
  opacity: 0;
  transform: translateY(10px);
}
.candidate-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity $transition-duration, transform $transition-duration;
}
.candidate-exit {
  opacity: 1;
  transform: translateY(0);
}
.candidate-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity $transition-duration, transform $transition-duration;
}
