// Form.scss
@import "../../styles/variables";
.form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .form-group {
      margin-bottom: 20px;
  
      label {
        display: block;
        font-weight: bold;
      }
  
      input[type="text"],
      input[type="email"],
      input[type="number"],
      textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
  
      input[type="checkbox"] {
        margin-top: 5px;
      }
  
      textarea {
        height: 100px;
      }
    }
  
    button[type="submit"] {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: $primary-color;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color:  rgb(224, 171, 132);
      }
    }
  }
  